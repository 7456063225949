import dayjs from "dayjs"
import { useStore } from "~/stores"
import type { ApiRecommendQuery } from "~/types/server/api/recommend/product"
import { useLocalUser } from "~/composables/useLocalUser"

export const getRecommendRequestBody = (limit: number): ApiRecommendQuery => {
  const { getLocalUserId } = useLocalUser()
  const store = useStore()
  return {
    local_user_id: getLocalUserId(),
    pv_products: store.productBrowsingHistory
      .filter((history) => history.pv_timestamp)
      .map((history) => ({
        product_id: history.document_id,
        pv_timestamp: history.pv_timestamp ?? null,
      }))
      .slice(0, 100),
    cart_products: store.cartList
      .filter((cart) => cart.add_to_cart_timestamp)
      .map((cart) => ({
        product_id: cart.product_id,
        add_to_cart_timestamp: cart.add_to_cart_timestamp ?? null,
      })),
    local_timestamp: dayjs().unix(), // 秒（ミリ秒ではないことに注意）
    limit,
  }
}
